import { string } from 'prop-types';

export const BellSVG = ({ backgroundFill, ...rest }) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={13}
    viewBox="0 0 14 13"
    fill="none"
    {...rest}
  >
    <path fill={backgroundFill || '#FFF'} d="M.3.5h12v12H.3z" />
    <path
      fill="currentColor"
      d="M9.01.73a4.211 4.211 0 0 1 1.844 1.16c.544.581.908 1.306 1.05 2.088v.027a4.293 4.293 0 0 1 .06.707v.99c.001.275.001.555.02.835.026.503.172.993.425 1.429.284.446.664.824 1.112 1.105a.62.62 0 0 1 .25.705.618.618 0 0 1-.601.447H9.837v.007c-.405 2.365-3.673 2.358-4.067-.008H2.434a.618.618 0 0 1-.61-.458.618.618 0 0 1 .273-.704c.552-.375.939-.782 1.183-1.248.244-.465.356-1.02.356-1.688V4.713a4.186 4.186 0 0 1 .068-.736A4.192 4.192 0 0 1 6.626.724C7.076-.24 8.56-.243 9.011.73Zm1.358 1.615a3.548 3.548 0 0 0-1.692-1.019.334.334 0 0 1-.233-.23C8.276.523 7.37.51 7.201 1.091a.335.335 0 0 1-.242.233 3.524 3.524 0 0 0-2.643 3.078 3.393 3.393 0 0 0-.013.31v1.412c0 .773-.134 1.423-.434 1.996-.287.55-.719 1.016-1.322 1.439h10.512a4.14 4.14 0 0 1-1.218-1.253 3.831 3.831 0 0 1-.517-1.72 11.34 11.34 0 0 1-.02-.88v-.993c0-.108 0-.212-.013-.315-.012-.102-.02-.195-.035-.278l-.005-.027a3.482 3.482 0 0 0-.88-1.749l-.003.001Z"
    />
  </svg>
);

BellSVG.propTypes = {
  backgroundFill: string,
  className: string,
};
