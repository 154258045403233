import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { bool, func, object, string } from 'prop-types';
import { Modal, Spin } from 'antd';

import { getProductsRecommended, getOtherColors } from 'actions/products';

import { useI18n } from 'context/i18n';
import { CloseModalSVG } from 'components/iconsSVG/close';
import ProductsRow from 'components/ProductsRow';

import { IS_NNORMAL } from 'utils/constants/system';
import { AUTO_BONDS } from 'utils/constants/cookies';

import { dataLayerHandleEvent } from 'utils/dataLayers';
import { getRealID, getCookie, COOKIES } from 'utils/helpers';

import { parseProductsEcommerceGA4 } from 'utils/gtmUtils';

import styles from './style.module.css';

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4.33,
  initialSlide: 0,
  swipeToSlide: true,
  easing: false,
  draggable: true,
  arrows: true,
  swipe: true,
  useTransform: false,
  touchThreshold: 50,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        arrows: false,
        slidesToShow: 4.6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 448,
      settings: {
        arrows: false,
        slidesToShow: 2.2,
        slidesToScroll: 1,
      },
    },
  ],
};

function ProductsModal({ productSheet, isModalVisible, handleCancel, realTarget, title, direct }) {
  const { t, locale, profileData } = useI18n();
  const [loading, setLoading] = useState(true);
  const [recommendedProducts, setRecommendedProducts] = useState(null);
  const [colorProducts, setColorProducts] = useState(null);
  const [error, setError] = useState(false);
  const [localVisible, setLocalVisible] = useState(direct ? false : isModalVisible); // para cargar y después mostrar

  const cancelWrapper = () => {
    setLocalVisible(false);
    handleCancel();
  };

  useEffect(() => {
    const getOtherProductsHandler = async () => {
      if (isModalVisible && recommendedProducts === null && colorProducts === null) {
        let errorCount = 0;
        let productsForEvent = [];
        const autoVoucher = getCookie(COOKIES.AUTO_BONDS) || '';
        const voucher = getCookie(COOKIES.BONDS) || '';
        const voucherDiscount = autoVoucher && autoVoucher === AUTO_BONDS.APPLY;
        const rqColors = {
          lang: locale,
          target: productSheet.targetId,
          id: productSheet.id,
          targetIdUrl: realTarget,
        };
        if (voucherDiscount) {
          rqColors.voucher = voucher;
        }
        try {
          const productResponse = await getOtherColors(rqColors);

          if (productResponse?.length > 0) {
            // const otherColorsWithUrl = addOriginToUrl(productResponse, origin);

            productsForEvent = [...productsForEvent, ...productResponse];

            setColorProducts(productResponse);
          } else {
            throw new Error(`Array for colors is empty: ${productResponse}`);
          }
        } catch (e) {
          console.error('No valid data for other colors ->', e);
          errorCount++;
        }

        try {
          const rqRecommended = {
            lang: locale,
            productCode: productSheet.id,
            ia: 'true',
            target: IS_NNORMAL ? productSheet.targetId : productSheet.targetId || undefined,
            forModal: true,
            targetIdUrl: realTarget,
          };
          if (voucherDiscount) {
            rqRecommended.voucher = voucher;
          }
          const response = await getProductsRecommended(rqRecommended);

          const productsToRecommend = response.recommendeds;

          if (productsToRecommend?.length > 0) {
            const reccommendedWithoutExtraNodes = productsToRecommend.map((product) => {
              delete product.colors;
              delete product.subtitleH1;
              delete product.sizes;
              return product;
            });
            productsForEvent = [...productsForEvent, ...productsToRecommend];
            setRecommendedProducts(reccommendedWithoutExtraNodes);
          } else {
            throw new Error(`Array for recommended is empty: ${productsToRecommend}`);
          }
        } catch (e) {
          console.error('No valid data for recommended products ->', e);
          errorCount++;
        }

        if (productsForEvent.length > 0) {
          const eventLayerGA4 = {
            event: 'view_item_list',
            nonInteraction: '1',
            ecommerce: {
              currency: profileData?.currencySap || (productsForEvent?.length ? productsForEvent?.[0]?.prices?.currency : ''),
              items: parseProductsEcommerceGA4(productsForEvent, 'variants', 'variants', 0),
            },
          };
          dataLayerHandleEvent(eventLayerGA4);
        }

        if (errorCount === 2) {
          if (direct) {
            cancelWrapper();
          } else {
            setError(true);
          }
        } else {
          setLocalVisible(true);
        }

        setLoading(false);
      }
    };

    getOtherProductsHandler();
  }, [isModalVisible]);

  return (
    <Modal
      title={title || t('ficha.producto', 'similar.products', '*** Similar products')}
      visible={direct ? localVisible : isModalVisible}
      width={'760px'}
      footer={null}
      wrapClassName={styles.modalWrapper}
      closeIcon={<CloseModalSVG />}
      closable
      destroyOnClose
      onCancel={cancelWrapper}
    >
      <Spin spinning={loading}>
        <div className={styles.modalContent}>
          {error && <p className="error">{t('ficha.producto', 'grid.load.failed', '***An error has occurred while loading the products.')}</p>}
          {colorProducts && (
            <div className={styles.modalColors} id="recomendador_colores">
              <ProductsRow
                title={t('ficha.producto', 'colores')}
                locale={locale}
                source={colorProducts}
                currentList="Other_colors"
                slider={true}
                camperlab={false}
                withHover={false}
                fixedGrid={false}
                forcedSettings={sliderSettings}
              />
            </div>
          )}
          {recommendedProducts && (
            <div className={styles.modalRecommended} id="recomendador_ia">
              <ProductsRow
                title={t('ficha.producto', 'tambien.puede.interesarte')}
                locale={locale}
                source={recommendedProducts}
                currentList="You_may_also_be_interested"
                slider={true}
                camperlab={false}
                withHover={false}
                fixedGrid={false}
                forcedSettings={sliderSettings}
              />
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
}

ProductsModal.propTypes = {
  isModalVisible: bool,
  productSheet: object,
  handleCancel: func,
  origin: string,
  realTarget: string,
  title: string,
  direct: bool,
};

function ProductsModalButton({ productSheet, origin, realTarget }) {
  const router = useRouter();
  const { t, profileData } = useI18n();

  const { query } = router;
  const { product } = query;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const familyRealID = getRealID('product', product);

  const showModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { prices, name, id } = productSheet;
    let oldPrice = 0;

    if (prices !== null && prices.previous !== undefined && prices.previous !== prices.current) {
      oldPrice = prices.previous;
    }

    const baseLayer = {
      event: 'view_similar_item',
      item: id,
      family: product,
      family_id: familyRealID,
      price_item: prices === null ? 0 : prices.current,
      old_price_item: oldPrice,
      material_name: name,
      ecommerce: {
        currencyCode: profileData?.currencySap || prices?.currency || profileData?.currency,
      },
    };
    dataLayerHandleEvent(baseLayer);

    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <a href="#" onClick={showModal} className={`${styles.modalButton} camper_similar_products`}>
        {t('ficha.producto', 'similar.products', '*** Similar products')}
      </a>
      <ProductsModal
        productSheet={productSheet}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        origin={origin}
        target={realTarget}
        direct={false}
      />
    </>
  );
}

ProductsModalButton.propTypes = {
  productSheet: object,
  origin: string,
  realTarget: string,
};

ProductsModal.Button = ProductsModalButton;

export default ProductsModal;
